'use client'

import { type PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'

import { AppSplash } from '@/components'
import { useHandleAuthState } from '@/hooks/useHandleAuthState'
import { ROUTES } from '@/lib/constants/routes'
import { AuthStatus } from '@/stores/useAuthStore'

export function PreAuthenticatedWrapper({
  children,
  fallbackUrl,
}: PropsWithChildren<{ fallbackUrl?: string }>) {
  const router = useRouter()
  const { status } = useHandleAuthState()

  const [isPending, setIsPending] = useState(true)

  useEffect(() => {
    let isActive = true
    if (!isActive) return

    switch (status) {
      case AuthStatus.LOADING:
        setIsPending(true)
        break
      case AuthStatus.MFA_TOTP_REQUIRED:
      case AuthStatus.MFA_UNREGISTERED:
        setIsPending(false)
        break
      case AuthStatus.AUTHENTICATED:
        router.replace(fallbackUrl || ROUTES.ROOT)
        break
      case AuthStatus.UNAUTHENTICATED:
        setIsPending(false)
        break
      default:
        break
    }

    return () => {
      isActive = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  if (isPending) {
    return <AppSplash />
  }

  return children
}
